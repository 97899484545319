import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { TextField } from 'formik-material-ui'
import css from './HRSettingPage.module.scss'
import { fetchCompanySettings, saveCompanySettings } from './HRSettingsPage.helper'
import { FormikHelpers } from 'formik/dist/types'
import NumberFormatInputField from '../../../components/NumberFormatInputField/NumberFormatInputField'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'

interface PropsI {}

interface FormPropsI {
  FYBudget: number | string
  FYUserBudget: number | string
  FYMonth?: number | string
}

const HRSettingPage: React.FC<PropsI> = () => {
  const [initialValues, setInitialValues] = useState<FormPropsI>({
    FYBudget: '',
    FYUserBudget: '',
    FYMonth: 'none',
  })

  useEffect(() => {
    fetchCompanySettings()
      .then((res) => {
        if (res.data) {
          setInitialValues({
            FYBudget: res?.data?.FYBudget,
            FYUserBudget: res?.data?.FYUserBudget,
            FYMonth: res?.data?.FYMonth || 'none',
          })
        } else {
          setInitialValues({
            FYBudget: 0,
            FYUserBudget: 0,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const validationSchema = yup.object({
    FYBudget: yup.number().label('Financial year budget').required(),
    FYUserBudget: yup.number().label('Financial year default user budget').required(),
    FYMonth: yup.number().label('Financial year ending month').required(),
  })

  const onSubmitForm = (values: any, formikHelpers: FormikHelpers<FormPropsI>) => {
    saveCompanySettings(values)
      .then(() => {
        formikHelpers.setSubmitting(false)
        setInitialValues(values)
      })
      .catch(() => {
        formikHelpers.setSubmitting(true)
      })
  }

  return (
    <MainDashboardWrapper title="Settings" page="corp-settings">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps: FormikProps<FormPropsI>) => {
          return (
            <Form className={css.form}>
              <FormControl variant={'outlined'}>
                <InputLabel>FY Ending Month</InputLabel>
                <Select
                  label={'FY Ending Month'}
                  value={formikProps.values.FYMonth}
                  onChange={(e) => formikProps.setFieldValue('FYMonth', e.target.value)}
                >
                  <MenuItem disabled value={'none'}>
                    Not Selected
                  </MenuItem>
                  <MenuItem value={0}>January</MenuItem>
                  <MenuItem value={1}>February</MenuItem>
                  <MenuItem value={2}>March</MenuItem>
                  <MenuItem value={3}>April</MenuItem>
                  <MenuItem value={4}>May</MenuItem>
                  <MenuItem value={5}>June</MenuItem>
                  <MenuItem value={6}>July</MenuItem>
                  <MenuItem value={7}>August</MenuItem>
                  <MenuItem value={8}>September</MenuItem>
                  <MenuItem value={9}>October</MenuItem>
                  <MenuItem value={10}>November</MenuItem>
                  <MenuItem value={11}>December</MenuItem>
                </Select>
                <FormHelperText>
                  The financial budget will be allocated automatically on the selected month above
                </FormHelperText>
              </FormControl>

              <Field
                className={css.input_field}
                component={TextField}
                name="FYBudget"
                variant="outlined"
                label="Budget for Financial Year"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="FYUserBudget"
                variant="outlined"
                label="Default employee budget per Financial Year"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <Button
                className={css.submit_button}
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Save'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </MainDashboardWrapper>
  )
}

export default HRSettingPage
