import React, { useEffect, useState } from 'react'
import css from './HRCurriculumForm.module.scss'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { TextField, Select as FormikSelect } from 'formik-material-ui'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import * as yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { fetchAllCategories } from '../../helpers/generalApiCalls'
import Stepper from '../../components/Stepper/Stepper'
import NumberFormatInputField from '../../components/NumberFormatInputField/NumberFormatInputField'
import Select from 'react-select'

interface PropsI extends MapStatePropsI, MapDispatchPropsI {
  initialValues: HRCurriculumFormValuesI
  onSubmit: (
    values: HRCurriculumFormValuesI,
    formikHelpers: FormikHelpers<HRCurriculumFormValuesI>
  ) => void
  isVisible: boolean
  additionalData: any
  isNew: boolean
}

// const NumberFormatInputField: React.FC = (props: any) => {
//   const { inputRef, onChange, ...rest } = props
//   return (
//     <NumberFormat
//       {...rest}
//       getInputRef={inputRef}
//       onValueChange={(values) => {
//         onChange({
//           target: {
//             name: props.name,
//             value: values.floatValue,
//           },
//         })
//       }}
//       decimalScale={2}
//       thousandSeparator
//       isNumericString
//       prefix="$"
//     />
//   )
// }

const HRCurriculumForm: React.FC<PropsI> = (props) => {
  const { isVisible, onSubmit, initialValues } = props
  const [categories, setCategories] = useState<any[]>([])
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  // const [employees, setEmployees] = useState<any[]>([])
  // const [employeesLoading, setEmployeesLoading] = useState(false)

  useEffect(() => {
    setCategoriesLoading(true)
    fetchAllCategories()
      .then((res) => {
        setCategoriesLoading(false)
        setCategories(res.data)
      })
      .catch((err) => {
        setCategoriesLoading(false)
        console.log(err)
      })

    // setEmployeesLoading(true)
    // fetchEmployeesInCompany()
    //   .then((res) => {
    //     setEmployees(res.data.employees)
    //     setEmployeesLoading(false)
    //   })
    //   .catch((err) => {
    //     setEmployeesLoading(false)
    //     console.log(err)
    //   })
  }, [])

  const validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    description: yup.string().label('Description').required(),
    budget: yup.string().label('Budget').required(),
    categories: yup.array().label('Categories').min(1).required(),
    minPrice: yup.number().label('Minimum Price').min(1).required(),
    maxPrice: yup.number().label('Maximum Price').required().moreThan(yup.ref('minPrice')),
    type: yup.string().label('Type').required(),
  })

  // if (!isVisible || categoriesLoading || employeesLoading) {
  if (!isVisible || categoriesLoading) {
    return <CircularProgress color="primary" />
  }

  // const remainingCompanyBudget =
  //   (additionalData?.company?.FYBudget || 0) - (additionalData?.company?.FYUtilized || 0)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formikProps: FormikProps<HRCurriculumFormValuesI>) => {
        return (
          <Form className={css.form}>
            <Stepper
              steps={[
                {
                  // title: `${isNew ? 'Add' : 'Edit'} Curriculum`,
                  title: `Add Curriculum`,
                  isSelected: true,
                },
                {
                  title: 'Add Listings',
                },
                {
                  title: 'Add Employees',
                },
              ]}
            />
            <Field
              className={css.input_field}
              component={TextField}
              name="name"
              variant="outlined"
              label="Name"
              color="primary"
              required
            />
            <Field
              className={css.input_field}
              component={TextField}
              name="description"
              variant="outlined"
              label="Description"
              color="primary"
              required
            />
            <Field
              className={css.input_field}
              component={TextField}
              name="budget"
              variant="outlined"
              label="Budget"
              color="primary"
              required
              InputProps={{
                inputComponent: NumberFormatInputField,
              }}
            />

            <div className={css.price_range}>
              <Field
                className={css.input_field}
                component={TextField}
                name="minPrice"
                variant="outlined"
                label="Minimum Price"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="maxPrice"
                variant="outlined"
                label="Maximum Price"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />
            </div>

            {/*<Field name="categories">*/}
            {/*  {(categoryFieldProps: FieldProps) => {*/}
            {/*    const { field, meta } = categoryFieldProps*/}

            {/*    return (*/}
            {/*      <Autocomplete*/}
            {/*        id={field.name}*/}
            {/*        onBlur={field.onBlur}*/}
            {/*        multiple*/}
            {/*        defaultValue={categories.filter((x) => initialValues.categories.includes(x.id))}*/}
            {/*        options={categories}*/}
            {/*        onChange={(event, values) => {*/}
            {/*          formikProps.setFieldValue(*/}
            {/*            field.name,*/}
            {/*            values.map((item) => item.id)*/}
            {/*          )*/}
            {/*        }}*/}
            {/*        getOptionLabel={(option) => option.name}*/}
            {/*        getOptionSelected={(option, value) => option.id === value.id}*/}
            {/*        filterSelectedOptions*/}
            {/*        renderInput={(params) => (*/}
            {/*          <MuiTextField*/}
            {/*            {...params}*/}
            {/*            className={css.input_field}*/}
            {/*            variant="outlined"*/}
            {/*            label="Categories *"*/}
            {/*            error={meta.touched && !!meta.error}*/}
            {/*            helperText={meta.touched && meta.error}*/}
            {/*            // placeholder="Favorites"*/}
            {/*          />*/}
            {/*        )}*/}
            {/*      />*/}
            {/*    )*/}
            {/*  }}*/}
            {/*</Field>*/}

            <Field name="type">
              {(fieldProps: FieldProps) => {
                const { field } = fieldProps

                return (
                  <FormControl component="fieldset">
                    <p className={css.label}>Curriculum Type</p>
                    <RadioGroup
                      name={field.name}
                      value={field.value}
                      onChange={(e) => formikProps.setFieldValue(field.name, e.target.value)}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel value="required" control={<Radio />} label="Required" />
                      <FormControlLabel
                        value="discretionary"
                        control={<Radio />}
                        label="Discretionary"
                      />
                    </RadioGroup>
                  </FormControl>
                )
              }}
            </Field>

            <Field name="categories">
              {(fieldProps: FieldProps) => {
                const { field } = fieldProps
                const customStyles = {
                  control: (provided: any, state: any) => {
                    return {
                      ...provided,
                      minHeight: 60,
                      '&:hover': { borderColor: state.isFocused ? css.primaryColor : 'gray' },
                      border: state.isFocused
                        ? `2px solid ${css.primaryColor}`
                        : '1px solid lightgray',
                      boxShadow: 'none',
                    }
                  },
                }

                return (
                  <Select
                    className={css.select_field}
                    styles={customStyles}
                    placeholder={'Select Categories...'}
                    onChange={(values) => {
                      // console.log({ values })
                      formikProps.setFieldValue(field.name, values)
                    }}
                    isMulti
                    color
                    name={field.name}
                    value={field.value}
                    options={categories.map((x) => ({ id: x.id, name: x.name }))}
                    // getOptionValue={(x) => x.name}
                    getOptionValue={(x) => x.id}
                    getOptionLabel={(x) => x.name}
                  />
                )
              }}
            </Field>

            {/*<Field name="attendees">*/}
            {/*  {(fieldProps: FieldProps) => {*/}
            {/*    const { field } = fieldProps*/}
            {/*    const customStyles = {*/}
            {/*      control: (provided: any, state: any) => {*/}
            {/*        return {*/}
            {/*          ...provided,*/}
            {/*          minHeight: 60,*/}
            {/*          '&:hover': { borderColor: state.isFocused ? css.primaryColor : 'gray' },*/}
            {/*          border: state.isFocused*/}
            {/*            ? `2px solid ${css.primaryColor}`*/}
            {/*            : '1px solid lightgray',*/}
            {/*          boxShadow: 'none',*/}
            {/*        }*/}
            {/*      },*/}
            {/*    }*/}

            {/*    let totalBudget = 0*/}

            {/*    for (const item of field.value) {*/}
            {/*      if (item.budget) {*/}
            {/*        totalBudget = totalBudget + item.budget*/}
            {/*      }*/}
            {/*    }*/}

            {/*    const setBudgetsForAll = () => {*/}
            {/*      if (budgetForAll) {*/}
            {/*        for (let i = 0; i < formikProps.values.attendees.length; i++) {*/}
            {/*          formikProps.setFieldValue(`attendees[${i}].budget`, budgetForAll)*/}
            {/*        }*/}
            {/*      }*/}
            {/*    }*/}

            {/*    return (*/}
            {/*      <>*/}
            {/*        <div style={{ marginTop: 20, color: 'gray' }}>*/}
            {/*          Remaining Company Budget: ${remainingCompanyBudget?.toLocaleString()}*/}
            {/*        </div>*/}
            {/*        <div style={{ marginTop: 20, color: 'gray' }}>*/}
            {/*          Current budget: ${totalBudget.toLocaleString()}*/}
            {/*        </div>*/}

            {/*        <Select*/}
            {/*          className={css.select_field}*/}
            {/*          styles={customStyles}*/}
            {/*          placeholder={'Select the Attendees...'}*/}
            {/*          onChange={(values) => {*/}
            {/*            formikProps.setFieldValue(field.name, values)*/}
            {/*          }}*/}
            {/*          isMulti*/}
            {/*          color*/}
            {/*          name={field.name}*/}
            {/*          value={field.value}*/}
            {/*          options={employees.map((x) => ({ id: x.id }))}*/}
            {/*          getOptionValue={(x) => x.id}*/}
            {/*          formatOptionLabel={(props) => {*/}
            {/*            const emp = employees.find((x) => x.id === props.id)*/}
            {/*            return (*/}
            {/*              <div className={css.user_option}>*/}
            {/*                <Avatar className={css.user_option_avatar} src={emp.profilePic}>*/}
            {/*                  {emp.firstName.charAt(0)}*/}
            {/*                  {emp.lastName.charAt(0)}*/}
            {/*                </Avatar>*/}
            {/*                <span className={css.user_option_name}>*/}
            {/*                  {emp.firstName} {emp.lastName}*/}
            {/*                </span>*/}
            {/*                /!*<span className={css.user_option_bio} title={emp.bio}>*!/*/}
            {/*                /!*  {emp.bio}*!/*/}
            {/*                /!*</span>*!/*/}
            {/*              </div>*/}
            {/*            )*/}
            {/*          }}*/}
            {/*        />*/}
            {/*        <div className={css.all_budget_base}>*/}
            {/*          <MuiTextField*/}
            {/*            value={budgetForAll}*/}
            {/*            onChange={(event) => setBudgetForAll(Number(event.target.value))}*/}
            {/*            variant="outlined"*/}
            {/*            size={'small'}*/}
            {/*            label={'Budget for all'}*/}
            {/*            InputProps={{*/}
            {/*              inputComponent: NumberFormatInputField,*/}
            {/*            }}*/}
            {/*          />*/}

            {/*          <Button*/}
            {/*            onClick={setBudgetsForAll}*/}
            {/*            size={'small'}*/}
            {/*            color="primary"*/}
            {/*            variant="contained"*/}
            {/*          >*/}
            {/*            Set Budget for all*/}
            {/*          </Button>*/}
            {/*        </div>*/}
            {/*      </>*/}
            {/*    )*/}
            {/*  }}*/}
            {/*</Field>*/}
            {/*<FieldArray name="attendees">*/}
            {/*  {(fieldArrayRenderProps: FieldArrayRenderProps) => {*/}
            {/*    const { form } = fieldArrayRenderProps*/}
            {/*    const { values } = form*/}
            {/*    const { attendees } = values*/}

            {/*    return (*/}
            {/*      <div className={css.attendees_array}>*/}
            {/*        {attendees.map((f: any, i: number) => {*/}
            {/*          const emp = employees.find((x) => x.id === f.id)*/}
            {/*          const empData = additionalData?.attendees?.find((x: any) => x.id === f.id)*/}
            {/*          const initialBudget = (empData?.utilized || 0) + (empData?.currentBudget || 0)*/}
            {/*          return (*/}
            {/*            <div key={f.id} className={css.attendee_item}>*/}
            {/*              <Tooltip title={emp.displayName}>*/}
            {/*                <div>*/}
            {/*                  <Avatar*/}
            {/*                    title={emp.displayName.charAt(0)}*/}
            {/*                    src={emp?.profilePic || ''}*/}
            {/*                  />*/}
            {/*                </div>*/}
            {/*              </Tooltip>*/}
            {/*              <Field*/}
            {/*                className={css.budget_input}*/}
            {/*                component={TextField}*/}
            {/*                size="small"*/}
            {/*                name={`attendees[${i}].budget`}*/}
            {/*                variant="outlined"*/}
            {/*                label={`${emp.displayName}'s current budget limit`}*/}
            {/*                color="primary"*/}
            {/*                required*/}
            {/*                InputProps={{*/}
            {/*                  inputComponent: NumberFormatInputField,*/}
            {/*                }}*/}
            {/*              />*/}
            {/*              <div style={{ marginBottom: '10px' }}>*/}
            {/*                {empData && `Initial Budget $${initialBudget?.toLocaleString()}`}*/}
            {/*              </div>*/}
            {/*              <div style={{ marginBottom: '10px' }}>*/}
            {/*                {empData && `Utilized $${(empData?.utilized || 0).toLocaleString()}`}*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          )*/}
            {/*        })}*/}
            {/*      </div>*/}
            {/*    )*/}
            {/*  }}*/}
            {/*</FieldArray>*/}
            <Button
              className={css.submit_button}
              color="primary"
              variant="contained"
              type="submit"
              disableElevation
              disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
            >
              {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Save and Continue'}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HRCurriculumForm)
