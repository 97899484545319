import React, { HTMLAttributes } from 'react'
import css from './AppLogo.module.scss'
import LogoImg from '../../assets/viveka-logo.png'
import { vivekaUrl } from '../../env-config'

interface PropsI extends HTMLAttributes<any> {
  linkTo?: string
}

const AppLogo: React.FC<PropsI> = (props) => {
  const { linkTo, className, ...rest } = props

  return (
    <a href={`${vivekaUrl}`} className={`${css.base} ${className}`} {...rest}>
      <img alt={'logo'} src={LogoImg} />
    </a>
  )
}

export default AppLogo
