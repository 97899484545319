import React, { Component, useCallback } from 'react'
import { connect } from 'react-redux'

import css from './HREmployeesPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BaseTable from '../../../components/BaseTable/BaseTable'
import { CellProps, Column } from 'react-table'
import { Avatar, Tooltip } from '@material-ui/core'
import { fetchEmployees } from '../../../ducks/employee.duck'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { Plus, PlusSquare } from 'react-feather'
import TableActions from '../../../components/TableActions/TableActions'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import EmployeeBulkBudgetAllocationPopup from '../../../components/EmployeeBulkBudgetAllocationPopup/EmployeeBulkBudgetAllocationPopup'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

interface StatesI {
  selectedRows: any[]
}

class HREmployeesPage extends Component<PropsI, StatesI> {
  constructor(props: PropsI) {
    super(props)

    this.state = {
      selectedRows: [],
    }

    this.onSelectData = this.onSelectData.bind(this)
  }

  componentDidMount() {
    const { fetchEmployees } = this.props
    fetchEmployees()
  }

  onSelectData(values: any) {
    this.setState({
      selectedRows: values,
    })
  }

  render() {
    const { employees, employeeFetchInProgress, history, fetchEmployees } = this.props

    const columns: Column[] = [
      {
        Header: 'Employee',
        accessor: 'displayName',
        Cell: (props: any) => {
          if (!props.value) return ''

          const img = props.row.original.profilePic
          return (
            <span className={css.employee_cell}>
              <Avatar src={img || ''}>{props.value.charAt(0)}</Avatar>
              <span>{props.value}</span>
            </span>
          )
        },
      },
      {
        Header: 'Roles',
        accessor: 'UserCompanyModel.roles',
        Cell: (props) => {
          if (!props.value) return ''
          return (
            <AvatarGroup max={5}>
              {props.value.map((item: string, index: number) => (
                <Tooltip key={index} title={`${item.toUpperCase()}`}>
                  <Avatar sizes="10px" alt={`${item.charAt(0)}`}>
                    {item.toUpperCase().charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )
        },
        disableSortBy: true,
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: 'Balance',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const total =
            (tableProps.row.original.UserCompanyModel.currentRequiredBudget || 0) +
            (tableProps.row.original.UserCompanyModel.currentDiscretionaryBudget || 0)
          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },

      {
        Header: 'Utilized',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const total =
            (tableProps.row.original.UserCompanyModel.requiredUtilized || 0) +
            (tableProps.row.original.UserCompanyModel.discretionaryUtilized || 0)
          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },

      {
        Header: 'Total',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const utilized =
            (tableProps.row.original.UserCompanyModel.requiredUtilized || 0) +
            (tableProps.row.original.UserCompanyModel.discretionaryUtilized || 0)

          const balance =
            (tableProps.row.original.UserCompanyModel.currentRequiredBudget || 0) +
            (tableProps.row.original.UserCompanyModel.currentDiscretionaryBudget || 0)

          const total = utilized + balance

          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },

      // {
      //   Header: 'Remaining required Budget',
      //   accessor: 'UserCompanyModel.currentRequiredBudget',
      //   Cell: (tableProps: any) => {
      //     if (tableProps.value && tableProps.value !== 0) {
      //       return '$' + tableProps?.value?.toLocaleString()
      //     } else {
      //       return '$0'
      //     }
      //   },
      // },
      // {
      //   Header: 'Required utilized Budget',
      //   accessor: 'UserCompanyModel.requiredUtilized',
      //   Cell: (tableProps: any) => {
      //     if (tableProps.value && tableProps.value !== 0) {
      //       return '$' + tableProps?.value?.toLocaleString()
      //     } else {
      //       return '$0'
      //     }
      //   },
      // },
      // {
      //   Header: 'Remaining discretionary Budget',
      //   accessor: 'UserCompanyModel.currentDiscretionaryBudget',
      //   Cell: (tableProps: any) => {
      //     if (tableProps.value && tableProps.value !== 0) {
      //       return '$' + tableProps?.value?.toLocaleString()
      //     } else {
      //       return '$0'
      //     }
      //   },
      // },
      // {
      //   Header: 'Required discretionary Budget',
      //   accessor: 'UserCompanyModel.discretionaryUtilized',
      //   Cell: (tableProps: any) => {
      //     if (tableProps.value && tableProps.value !== 0) {
      //       return '$' + tableProps?.value?.toLocaleString()
      //     } else {
      //       return '$0'
      //     }
      //   },
      // },

      // {
      //   Header: 'Became an employee on',
      //   accessor: 'UserCompanyModel.createdAt',
      //   Cell: (tableProps) => {
      //     const dateString = moment(tableProps.value).format('DD / M / YYYY')
      //     const fromNow = moment(tableProps.value).fromNow()
      //     return (
      //       <Tooltip title={fromNow}>
      //         <p>{dateString}</p>
      //       </Tooltip>
      //     )
      //   },
      // },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: (tableProps: any) => (
          <TableActions
            actions={[
              {
                icon: faEye,
                action: () => history.push(`/hr/employee/view/${tableProps.value}`),
                tooltip: 'View',
              },
            ]}
          />
        ),
        disableSortBy: true,
      },
    ]

    return (
      <MainDashboardWrapper title="Employees" page="corp-employees">
        <div className={css.actions}>
          <EmployeeBulkBudgetAllocationPopup
            trigger={<div className={css.text_button}>Budget Allocation</div>}
            onSuccessCallback={() => fetchEmployees()}
          />
          <PrimaryButton name={'Add'} to="/hr/employee/add" icon={PlusSquare} />
        </div>
        <BaseTable
          columns={columns}
          data={employees}
          loading={employeeFetchInProgress}
          isSelectable
        />
      </MainDashboardWrapper>
    )
  }
}

interface MapStatePropsI {
  employees: any[]
  employeeFetchInProgress: boolean
}

interface MapDispatchPropsI {
  fetchEmployees: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    employees: state.employee.employees,
    employeeFetchInProgress: state.employee.employeeFetchInProgress,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {
    fetchEmployees: () => dispatch(fetchEmployees()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HREmployeesPage))
