import React, { CSSProperties, MouseEventHandler } from 'react'
import css from './TableActions.module.scss'
import '@szhsin/react-menu/dist/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CircularProgress, Tooltip } from '@material-ui/core'

interface ActionI {
  icon: IconProp
  action: any
  tooltip?: string
  isLoading?: boolean
  buttonStyle?: CSSProperties
}

interface PropsI {
  actions: ActionI[]
}

const TableActions: React.FC<PropsI> = (props) => {
  const { actions } = props

  return (
    <div className={css.base}>
      {actions.map((item, index) => {
        console.log('item.action')
        return (
          <Tooltip key={index} title={item.tooltip || ''}>
            {item.isLoading ? (
              <CircularProgress />
            ) : (
              <div className={css.icon_button} style={item.buttonStyle} onClick={item.action}>
                <FontAwesomeIcon className={css.icon} icon={item.icon} />
              </div>
            )}
          </Tooltip>
        )
      })}
    </div>
  )
}

export default TableActions
