import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import css from './HRCurriculumPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BaseTable from '../../../components/BaseTable/BaseTable'
import {
  faEdit,
  faEye,
  faTasks,
  faUsers,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import TableActions from '../../../components/TableActions/TableActions'
import { AvatarGroup } from '@material-ui/lab'
import { Avatar, Tooltip } from '@material-ui/core'
import { deleteCurriculum, fetchCurriculums } from './HRCurriculumPage.helper'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { Plus, PlusSquare } from 'react-feather'
import EmployeeBulkBudgetAllocationPopup from '../../../components/EmployeeBulkBudgetAllocationPopup/EmployeeBulkBudgetAllocationPopup'
import CurriculumEmployeeBudgetAllocatePopup from '../../../components/CurriculumEmployeeBudgetAllocatePopup/CurriculumEmployeeBudgetAllocatePopup'
import CurriculumBulkBudgetAllocationPopup from '../../../components/CurriculumBulkBudgetAllocationPopup/CurriculumBulkBudgetAllocationPopup'
import { useConfirm } from 'material-ui-confirm'

interface PropsI extends RouteComponentProps {}

interface StatesI {
  curriculums: any[]
  dataLoading: boolean
}

const HRCurriculumPage: React.FC<PropsI> = (props) => {
  const { history } = props
  const [curriculum, setCurriculum] = useState([])
  const confirm = useConfirm()

  function pageLoad() {
    fetchCurriculums().then((res) => {
      setCurriculum(res?.data)
    })
  }

  useEffect(() => {
    pageLoad()
  }, [])

  const columns: any[] = [
    {
      Header: 'Curriculum',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (tableProps: any) => {
        return (tableProps?.value || '').toUpperCase()
      },
    },
    {
      Header: 'Attendees',
      accessor: 'attendees',
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps?.value?.map((item: any) => (
              <Tooltip key={item.id} title={`${item.firstName} ${item.lastName}`}>
                <Avatar alt={`${item.firstName} ${item.lastName}`} src={item.profilePic || ''} />
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Listings',
      accessor: 'listings',
      disableSortBy: true,
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps?.value?.map((item: any) => (
              <Tooltip key={item.id} title={`${item.name}`}>
                <Avatar sizes="10px" alt={`${item.name.charAt(0)}`}>
                  {item.name.charAt(0)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
    },

    {
      Header: 'Categories',
      accessor: 'categories',
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps.value.map((item: any) => (
              <Tooltip key={item.id} title={`${item.name}`}>
                <Avatar sizes="10px" alt={`${item.name.charAt(0)}`}>
                  {item.name.charAt(0)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
      disableSortBy: true,
    },
    // {
    //   Header: 'Minimum Price',
    //   accessor: 'minPrice',
    //   Cell: (tableProps: any) => {
    //     if (tableProps.value && tableProps.value !== 0) {
    //       return '$' + tableProps?.value?.toLocaleString()
    //     } else {
    //       return '$0'
    //     }
    //   },
    // },
    // {
    //   Header: 'Maximum Price',
    //   accessor: 'maxPrice',
    //   Cell: (tableProps: any) => {
    //     if (tableProps.value && tableProps.value !== 0) {
    //       return '$' + tableProps?.value?.toLocaleString()
    //     } else {
    //       return '$0'
    //     }
    //   },
    // },
    {
      Header: 'Current Expenditure',
      accessor: 'utilized',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Remaining Budget',
      accessor: 'currentBudget',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    // {
    //   Header: 'Contract',
    //   accessor: 'contract',
    // },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (tableProps: any) => (
        <TableActions
          actions={[
            {
              icon: faEye,
              action: () => history.push(`/hr/curriculum/details/${tableProps.row.original.id}`),
              tooltip: 'Details',
            },
            {
              icon: faEdit,
              action: () => history.push(`/hr/curriculum/edit/${tableProps.row.original.id}`),
              tooltip: 'Edit',
            },
            {
              icon: faTasks,
              action: () =>
                history.push(`/hr/curriculum/edit/${tableProps.row.original.id}/listings`),
              tooltip: 'Programs',
            },
            {
              icon: faUsers,
              action: () =>
                history.push(`/hr/curriculum/edit/employees/${tableProps.row.original.id}`),
              tooltip: 'Employees',
            },
            {
              icon: faTrash,
              action: () => {
                console.log('delete')
                confirm().then(() => {
                  deleteCurriculum(tableProps.row.original.id).then(() => {
                    pageLoad()
                  })
                })
              },
              tooltip: 'Delete',
              buttonStyle: {
                backgroundColor: '#730000',
              },
            },
          ]}
        />
      ),
      disableSortBy: true,
    },
  ]

  // let currentBudget = 0
  // let utilized = 0
  // for (const curriculum of this.state.curriculums) {
  //   currentBudget += Number(curriculum.currentBudget)
  //   utilized += Number(curriculum.utilized)
  // }
  //
  // const initialBudget = currentBudget + utilized

  return (
    <MainDashboardWrapper title="Curriculum" page="corp-curriculum">
      <div className={css.actions}>
        <CurriculumBulkBudgetAllocationPopup
          trigger={<div className={css.text_button}>Budget Allocation</div>}
          onSuccessCallback={() => pageLoad()}
        />
        <PrimaryButton
          style={{ alignSelf: 'flex-end' }}
          name={'Add'}
          to="/hr/curriculum/edit"
          icon={Plus}
        />
      </div>

      <BaseTable
        columns={columns}
        data={curriculum}
        // loading={this.state.dataLoading}
        isSelectable
      />
    </MainDashboardWrapper>
  )
}

export default withRouter(HRCurriculumPage)
