import React, { useState } from 'react'
import css from './CurriculumCoachOfferingPopup.module.scss'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Avatar } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element
  coach: any
  onSelectCurriculum: (listingId: number) => void
}

const CurriculumCoachOfferingPopup: React.FC<PropsI> = (props) => {
  const { trigger, coach, onSelectCurriculum } = props
  const [bioOpen, setBioOpen] = useState<boolean>(false)

  const isSmallView = window.innerWidth < 768
  let popupContentStyle: React.CSSProperties = {}
  if (isSmallView) {
    popupContentStyle.width = '100vw'
  } else {
    popupContentStyle.width = '750px'
  }
  return (
    <Popup contentStyle={popupContentStyle} trigger={trigger} modal>
      {(close: any) => (
        <div className={css.base}>
          <div className={css.profile}>
            <Avatar src={coach.profilePic} />
            <div className={css.profile_desc}>
              <div>{coach.displayName}</div>
              <div className={css.profile_location}>{coach.location}</div>
            </div>
          </div>
          <div className={css.bio} style={{ height: bioOpen ? 500 : 100 }}>
            {ReactHtmlParser(decode(coach.bio))}
          </div>
          <div className={css.show_more} onClick={() => setBioOpen(!bioOpen)}>
            {bioOpen ? 'Collapse' : 'Show more'}
          </div>
          <br />
          <div className={css.listings}>
            {coach?.listings?.map((item: any) => {
              return (
                <div key={item.id} className={css.listing_card}>
                  <div className={css.listing_header}>
                    <div className={css.listing_name}>{item.name}</div>
                    <div className={css.listing_price}>
                      $ {item?.price?.toLocaleString()} / {item?.type?.charAt(0)}
                    </div>
                  </div>
                  <div>{ReactHtmlParser(decode(item.description?.substring(0, 200)))}</div>
                  <div className={css.listing_footer}>
                    <div className={css.listing_categories}>
                      {item?.typeOfService.map((x: any, index: number) => {
                        return (
                          <div key={index} className={css.category_item}>
                            {x}
                          </div>
                        )
                      })}
                    </div>
                    <div onClick={() => onSelectCurriculum(item.id)} className={css.button}>
                      Add to curriculum
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(CurriculumCoachOfferingPopup)
