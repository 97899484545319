import React, { useEffect, useState } from 'react'
import css from './HRCurriculumViewPage.module.scss'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { fetchCurriculumDetails } from './HRCurriculumViewPage.helper'
import { Column } from 'react-table'
import BaseTable from '../../../components/BaseTable/BaseTable'
import moment from 'moment'
import { Avatar, Tooltip } from '@material-ui/core'
import { fetchCurriculumEmployees } from '../HRCurriculumEditEmployees/HRCurriculumEditEmployees.helper'
import { fetchCurriculum } from '../EditHRCurriculumPage/EditHRCurriculumPage.helper'
import CurriculumEmployeeBulkBudgetAllocationPopup from '../../../components/CurriculumEmployeeBulkBudgetAllocationPopup/CurriculumEmployeeBulkBudgetAllocationPopup'

interface PropsI extends RouteComponentProps<{ id: string }> {}

const HRCurriculumViewPage: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const [enrolledCurriculums, setEnrolledCurriculums] = useState<any[]>([])
  const [employees, setEmployees] = useState<any[]>([])
  const [curriculum, setCurriculum] = useState<any>(null)
  useEffect(() => {
    fetchCurriculumDetails(match.params.id)
      .then((res) => {
        setEnrolledCurriculums(res.data)
      })
      .catch((err) => console.log(err))

    fetchCurriculumEmployees(match.params.id)
      .then((res) => {
        setEmployees(res.data)
      })
      .catch((err) => console.log(err))

    fetchCurriculum(match.params.id)
      .then((res) => setCurriculum(res.data))
      .catch((err) => console.log(err))
  }, [match.params.id])

  const columns: Column[] = [
    {
      Header: 'Attendee',
      accessor: 'attendee.displayName',
    },
    {
      Header: 'Coach',
      accessor: 'coach.displayName',
    },
    {
      Header: 'Program',
      accessor: 'listing.name',
    },
    {
      Header: 'Amount',
      accessor: 'price',
      Cell: (tableProps: any) => {
        return tableProps.value
          ? `$${tableProps.value.toLocaleString()}`
          : `$${(0).toLocaleString()}`
      },
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },
  ]

  return (
    <MainDashboardWrapper title="Curriculum Details" page="corp-curriculum">
      <div className={css.details}>
        <div className={css.budget_card}>
          <p>Curriculum Budget</p>
          <p>${(curriculum?.currentBudget || 0).toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Utilized</p>
          <p>${(curriculum?.utilized || 0).toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Allocation</p>
          <p>
            ${((curriculum?.utilized || 0) + (curriculum?.currentBudget || 0)).toLocaleString()}
          </p>
        </div>
      </div>
      <div className={css.employees_title}>
        <p>Employees</p>
        <CurriculumEmployeeBulkBudgetAllocationPopup
          trigger={<div className={css.allocate_button}>Bulk Budget Allocate</div>}
          onSuccessCallback={() => {}}
          curriculumId={match.params.id}
        />
      </div>

      <div className={css.employees}>
        {employees.map((e, i) => {
          return (
            <div key={i} className={css.user_name_base}>
              <Avatar src={e.profilePic} />
              <div className={css.user_name}>
                <p>{e.displayName}</p>
                <p>{e.location}</p>
              </div>
              <span
                className={css.user_action}
                onClick={() =>
                  history.push(`/hr/curriculum/details/${match.params.id}/${e.id}/enrollments`)
                }
              >
                View
              </span>
            </div>
          )
        })}
      </div>

      <BaseTable columns={columns} data={enrolledCurriculums} />
    </MainDashboardWrapper>
  )
}

export default withRouter(HRCurriculumViewPage)
