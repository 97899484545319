import axios, { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { isAuthenticated } from '../helpers/authentication'
import { backendUrl } from '../env-config'
import { authAxios } from '../helpers/axios'
import { ThunkDispatch } from 'redux-thunk'

// ================ Action types ================ //

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST'
export const LOGIN_COMPLETE = 'app/Auth/LOGIN_COMPLETE'

export const SET_IS_AUTHENTICATED = 'app/Auth/SET_IS_AUTHENTICATED'

// ================ Reducer ================ //

const initialState: ReduxAuthStateI = {
  isAuthenticated: false,
  // authInfo: false,
  loginError: null,
  loginInProgress: false,
}

export default function reducer(state = initialState, action: AppActionI) {
  const { type, payload } = action
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
      }

    case LOGIN_COMPLETE:
      return {
        ...state,
        loginInProgress: false,
        isAuthenticated: true,
      }

    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      }

    default:
      return state
  }
}

// ================ Action creators ================ //

const loginRequest = (): AppActionI => ({ type: LOGIN_REQUEST })
const loginComplete = (): AppActionI => ({ type: LOGIN_COMPLETE })

export const setIsAuthenticated = (isAuthenticated: boolean): AppActionI => ({
  type: SET_IS_AUTHENTICATED,
  payload: isAuthenticated,
})

// ================ Thunks ================ //

export const setAuthentication = () => (dispatch: Dispatch, getState: () => AppStateI) => {
  dispatch(setIsAuthenticated(isAuthenticated()))
}

export const login = (email: string, password: string) => async (
  dispatch: Dispatch,
  getState: () => AppStateI
) => {
  dispatch(loginRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await axios.post<ApiResponseI>(`${backendUrl}/auth/login`, {
        email,
        password,
      })

      localStorage.setItem('access_token', res.data.data?.token.access_token)
      localStorage.setItem('refresh_token', res.data.data?.token.refresh_token)

      const company = res.data.data?.user.companies.find(
        (x: any) => x.id === res.data.data?.user.currentCompanyId
      )

      const userRoles = company?.UserCompanyModel.roles

      if (userRoles) {
        localStorage.setItem('roles', userRoles)
      }

      dispatch(loginComplete())
      resolve({
        data: {
          user: res.data.data?.user,
          roles: userRoles || [],
        },
        success: true,
      })
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e
      dispatch(loginComplete())
      reject(err.response?.data.message || err.message)
    }
  })
}

interface SignupValuesI {
  email: string
  password: string
  firstName: string
  lastName: string
  displayName: string
}

export const signUp = (values: SignupValuesI) => async (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>,
  getState: () => AppStateI
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/auth/signup`, {
        values: values,
      })
      // await dispatch(login(values.email, values.password))
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const logout = () => async (dispatch: Dispatch, getState: () => AppStateI) => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('roles')
  return authAxios
    .post(`${backendUrl}/auth/logout`, {})
    .then((res: AxiosResponse) => {
      dispatch(setIsAuthenticated(false))
    })
    .catch((err: AxiosError) => {})
}
