import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchEmployeeEnrolledCurriculums = async (employeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/employee-enrolled-curriculum`, {
        employeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchEmployeeDetails = async (employeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/user-details`, {
        employeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchBudgetRecords = async (employeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/employee-budget-details`, {
        employeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const allocateBudget = async (
  employeeId: string,
  amount: number | string,
  remark: string,
  type: string,
  budgetType: string
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/allocate-employee-budget`, {
        employeeId,
        amount,
        remark,
        type,
        budgetType,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
