import React, { useEffect, useState } from 'react'
import css from './MonthlyDashboardView.module.scss'
import { Bar, Doughnut, Line } from 'react-chartjs-2'
import { fetchDashboardDataMonthly, fetchEmployeesDashboardMonthly } from '../HRDashboard.helper'
import { env } from '../../../../env-config'
interface PropsI {}

const MonthlyDashboardView: React.FC<PropsI> = (props) => {
  const [budgetData, setBidgetData] = useState<any>()
  // const [employeesData, setEmployeesData] = useState<any>()
  useEffect(() => {
    fetchDashboardDataMonthly().then((res) => setBidgetData(res.data))
    // fetchEmployeesDashboardMonthly().then((res) => setEmployeesData(res.data))
  }, [])

  const years = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'oct', 'nov', 'dec']
  const budgetBarData: any = {
    labels: years,
    datasets: [
      {
        label: '# Utilized Budget',
        data: budgetData?.budget.utilized,
        backgroundColor: css.chartColor2,
      },
      {
        label: '# Allocated Budget',
        data: budgetData?.budget.allocation,
        backgroundColor: css.chartColor1,
      },
    ],
  }

  const lineData = {
    labels: years,
    datasets: [
      {
        label: 'D',
        data: [1000, 5800, 4700, 3540, 4780, 5890, 2540, 6475, 8750, 1250, 3455, 4522],
        yAxisID: 'y',
        backgroundColor: css.chartColor3,
        borderColor: css.chartColor3,
      },
      {
        label: 'R',
        data: [3000, 4800, 5700, 7540, 1780, 8890, 9540, 4475, 8750, 2250, 2455, 6522],
        yAxisID: 'y1',
        backgroundColor: css.chartColor1,
        borderColor: css.chartColor1,
      },
    ],
  }

  const horizontalOptions = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: 'Employee Budget Utillization',
      },
    },
  }

  const horizontalData = {
    labels: years,
    datasets: [
      {
        label: 'Utillized ',
        data: [1000, 5800, 4700, 3540, 4780, 5890, 2540, 6475, 8750, 1250, 3455, 4522],
        yAxisID: 'y',
        backgroundColor: css.chartColor3,
        borderColor: css.chartColor3,
      },
      {
        label: 'Required',
        data: [3000, 4800, 5700, 7540, 1780, 8890, 9540, 4475, 8750, 2250, 2455, 6522],
        yAxisID: 'y1',
        backgroundColor: css.chartColor1,
        borderColor: css.chartColor1,
      },
    ],
  }

  const dataDoughnut = {
    labels: ['Utilized', 'FABudget'],
    datasets: [
      {
        data: [budgetData?.company?.utilized, budgetData?.company?.FYBudget],
        backgroundColor: [css.chartColor1, css.chartColor2],
        borderColor: [css.chartColor1, css.chartColor1],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className={css.base}>
      {/*<div className={css.budget_items}>*/}
      <div className={css.data_item_wrapper} style={{ gridArea: 'a' }}>
        <p>Company Budget</p>
        <Bar data={budgetBarData} />
      </div>
      <div className={css.data_item_wrapper} style={{ gridArea: 'b' }}>
        <p>Company FYLimit / Utilization</p>
        <div style={{ width: 260 }}>
          <Doughnut data={dataDoughnut} />
        </div>
      </div>
      {/*</div>*/}
      {(env === 'LOCAL' || env === 'DEV' || env === 'STAGING') && (
        <>
          <div className={css.nps_div} style={{ gridArea: 'c' }}>
            <div className={css.nps_content}>
              <p>NPS</p>
              <div>Overall Employee NPS</div>
            </div>

            <div className={css.nps_value}>8.3</div>
          </div>

          <div className={css.nps_utilization} style={{ gridArea: 'd' }}>
            <p>Utilization</p>
            <div>Most popular days for sessions</div>

            <Line data={lineData} />
          </div>
          <div className={css.nps_breakdown} style={{ gridArea: 'e' }}>
            <Bar options={horizontalOptions} data={horizontalData} />
          </div>
        </>
      )}
    </div>
  )
}

export default MonthlyDashboardView
