import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FormikHelpers } from 'formik/dist/types'
import { AlertManager, withAlert } from 'react-alert'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import css from '../../../forms/HRCurriculumForm/HRCurriculumForm.module.scss'
import { TextField } from 'formik-material-ui'
import {
  Chip,
  CircularProgress,
  TextField as MuiTextField,
  Tooltip,
  Button,
} from '@material-ui/core'
import * as yup from 'yup'
import { fetchMyInvitations, inviteUser, reInviteUser } from './AddHREmployeesPage.helper'
import { useConfirm } from 'material-ui-confirm'
import { Autocomplete } from '@material-ui/lab'
import BaseTable from '../../../components/BaseTable/BaseTable'
import { CellProps, Column } from 'react-table'
import moment from 'moment'
import TableActions from '../../../components/TableActions/TableActions'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { getCompanyRoles } from '../../../helpers/authentication'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'

interface FormValuesI {
  email: string
  roles: string[]
}

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {
  alert: AlertManager
}

const AddHREmployeesPage: React.FC<PropsI> = (props) => {
  const { alert } = props
  const confirm = useConfirm()
  const [myInvitations, setMyInvitations] = useState([])
  const [reInviteLoading, setReInviteLoading] = useState<number | null>(null)

  const loadPage = () => {
    fetchMyInvitations().then((res) => {
      setMyInvitations(res.data)
    })
  }

  const reInvite = (id: number) => {
    confirm().then(() => {
      setReInviteLoading(id)
      reInviteUser(id)
        .then((res) => {
          setReInviteLoading(null)
          alert.success(res.message)
        })
        .catch((err) => {
          setReInviteLoading(null)
          alert.error(err)
        })
    })
  }

  useEffect(() => {
    loadPage()
  }, [])

  const columns: Column[] = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Is Accepted',
      accessor: 'isAccepted',
      Cell: (props) => {
        return <span>{props.value ? 'Yes' : 'No'}</span>
      },
    },
    {
      Header: 'Roles',
      accessor: 'roles',
      disableGlobalFilter: true,
      Cell: (props) => {
        return (
          <span>
            {props.value.map((item: any) => (
              <Chip style={{ marginRight: '10px' }} key={item} label={item.toUpperCase()} />
            ))}
          </span>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Sent',
      accessor: 'createdAt',
      Cell: (tableProps) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
      disableGlobalFilter: true,
    },
    {
      Header: 'Action',
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: (tableProps: CellProps<any>) => {
        const actions = tableProps.row.original.isAccepted
          ? []
          : [
              {
                icon: faRedo,
                action: () => reInvite(tableProps.row.original.id),
                tooltip: 'Resend the invitation',
                isLoading: tableProps.row.original.id === reInviteLoading,
              },
            ]
        return <TableActions actions={actions} />
      },
    },
  ]

  const onSubmitForm = (values: FormValuesI, formikHelpers: FormikHelpers<FormValuesI>) => {
    // addUserToCompanyA(values)
    //   .then((res) => {
    //     alert.success(res.message)
    //     formikHelpers.setSubmitting(false)
    //     history.push('/hr/employee')
    //   })
    //   .catch((err) => {
    //     alert.error(err)
    //     formikHelpers.setSubmitting(false)
    //     history.push('/hr/employee')
    //   })

    confirm()
      .then(() => {
        inviteUser(values)
          .then(() => {
            formikHelpers.setSubmitting(false)
            loadPage()
          })
          .catch((err) => {
            alert.error(err)
            formikHelpers.setSubmitting(false)
          })
      })
      .catch(() => formikHelpers.setSubmitting(false))
  }

  const validationSchema = yup.object({
    email: yup.string().label('Email').required().email(),
    roles: yup.array().of(yup.string()).label('Roles').required().min(1),
  })

  let roleOptions = ['hr', 'employee']

  const roles = getCompanyRoles()

  if (roles.includes('admin')) {
    roleOptions.push('admin')
  }

  const initialValues: FormValuesI = { email: '', roles: [] }
  return (
    <MainDashboardWrapper title="Invite Employees" page="corp-employees">
      {/*<HREmployeeForm onSubmit={this.onSubmitForm} />*/}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
      >
        {(formikProps: FormikProps<FormValuesI>) => {
          return (
            <Form className={css.form}>
              <Field
                className={css.input_field}
                component={TextField}
                name="email"
                variant="outlined"
                label="Email"
                color="primary"
                required
              />

              <Field name="roles">
                {(fieldProps: FieldProps) => {
                  const { field, meta } = fieldProps
                  return (
                    <Autocomplete
                      id="roles"
                      onBlur={field.onBlur}
                      multiple
                      options={roleOptions}
                      onChange={(event, value) => {
                        formikProps.setFieldValue('roles', value)
                      }}
                      // getOptionLabel={(option) => option.name}
                      // getOptionSelected={(option, value) => option.id === value.id}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          className={css.input_field}
                          variant="outlined"
                          label="Roles *"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          // placeholder="Favorites"
                        />
                      )}
                    />
                  )
                }}
              </Field>

              <Button
                className={css.submit_button}
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Invite'}
              </Button>
            </Form>
          )
        }}
      </Formik>

      <BaseTable columns={columns} data={myInvitations} />
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withAlert()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddHREmployeesPage))
)
