import React, { BaseSyntheticEvent, useEffect } from 'react'
// import css from './HRDashboardPage.module.scss'
// import { ThunkDispatch } from 'redux-thunk'
// import { Bar, Doughnut } from 'react-chartjs-2'
// import { fetchDashboardData, fetchEmployeesDashboardData } from './HRDashboard.helper'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { Tab, Tabs } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import MonthlyDashboardView from './MonthlyDashboardView/MonthlyDashboardView'
import YearlyDashboardView from './YearlyDashboardView/YearlyDashboardView'
import ViewListingPopup from '../../../components/ViewListingPopup/ViewListingPopup'

interface PropsI extends RouteComponentProps<{ tab: string }> {}

const HRDashboardPage: React.FC<PropsI> = (props) => {
  const { history, match } = props

  // const [data, setData] = useState<any>(null)
  // const [yearlyEmployeesData, setYearlyEmployeesData] = useState<any[]>([])
  useEffect(() => {
    // fetchDashboardData()
    //   .then((res) => {
    //     setData(res.data)
    //     console.log({ res })
    //   })
    //   .catch((err) => {
    //     console.log({ err })
    //   })
    //
    // fetchEmployeesDashboardData()
    //   .then((res) => setYearlyEmployeesData(res.data))
    //   .catch((err) => console.log(err))
  }, [])

  // console.log({ yearlyEmployeesData, data })
  //
  // const barData = {
  //   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'oct', 'nov', 'dec'],
  //   datasets: [
  //     {
  //       label: '# Utilized Budget',
  //       data: data?.budget?.utilized,
  //       backgroundColor: css.chartColor2,
  //     },
  //     // {
  //     //   label: '# Utilized',
  //     //   data: [2, 3, 20, 5, 1, 4],
  //     //   backgroundColor: css.chartColor2,
  //     // },
  //     // {
  //     //   label: '# Compulsory',
  //     //   data: [3, 7, 9, 10, 22, 30],
  //     //   backgroundColor: css.chartColor3,
  //     // },
  //     // {
  //     //   label: '# Discretionary',
  //     //   data: [3, 10, 13, 15, 5, 25],
  //     //   backgroundColor: css.chartColor4,
  //     // },
  //   ],
  // }
  //
  // const BarOptions = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // }
  //
  // const dataDoughnut = {
  //   labels: ['FAUtilization', 'FABudget'],
  //   datasets: [
  //     {
  //       data: [data?.company?.FYUtilized, data?.company?.FYBudget],
  //       backgroundColor: [css.chartColor1, css.chartColor2],
  //       borderColor: [css.chartColor1, css.chartColor1],
  //       borderWidth: 1,
  //     },
  //   ],
  // }

  // return (
  //   <MainDashboardWrapper title="Dashboard" page="corp-dashboard">
  //     <div className={css.budget_wrapper}>
  //       <div className={css.budget_canv}>
  //         <Doughnut data={dataDoughnut} />
  //       </div>
  //     </div>
  //
  //     <div className={css.FY_wrapper}>
  //       <div className={css.FY_item}>
  //         <Bar data={barData} options={BarOptions} />
  //       </div>
  //     </div>
  //
  //     <div className={css.employee_charts}>
  //       {yearlyEmployeesData.map((item, index) => {
  //         const empBarData = {
  //           labels: [
  //             'Jan',
  //             'Feb',
  //             'Mar',
  //             'Apr',
  //             'May',
  //             'Jun',
  //             'Jul',
  //             'Aug',
  //             'Sep',
  //             'oct',
  //             'nov',
  //             'dec',
  //           ],
  //           datasets: [
  //             {
  //               label: '# Utilized Budget',
  //               data: item.data,
  //               backgroundColor: css.chartColor2,
  //             },
  //           ],
  //         }
  //         return (
  //           <div key={index} className={css.employee_chart}>
  //             <p>{item.name}</p>
  //             <Bar data={empBarData} options={BarOptions} />
  //           </div>
  //         )
  //       })}
  //     </div>
  //   </MainDashboardWrapper>
  // )

  function renderComponentByTab(tab: string) {
    switch (tab) {
      case 'monthly':
        return <MonthlyDashboardView />

      case 'annually':
        return <YearlyDashboardView />

      default:
        return <MonthlyDashboardView />
    }
  }

  const onTabChange = (event: BaseSyntheticEvent, tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        history.push(`/hr/dashboard/monthly`)
        break

      case 1:
        history.push(`/hr/dashboard/annually`)
        break
    }
  }

  const selectedTabIndex = match.params.tab === 'annually' ? 1 : 0

  return (
    <MainDashboardWrapper title="Dashboard" page="corp-dashboard">
      <Tabs
        value={selectedTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Monthly" />
        <Tab label="Annually" />
      </Tabs>
      {renderComponentByTab(match.params.tab)}
    </MainDashboardWrapper>
  )
}

export default withRouter(HRDashboardPage)
