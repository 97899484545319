import React, { useEffect, useState } from 'react'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { Column } from 'react-table'
import { fetchCompanyPurchases } from '../HRBillingPage.helper'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'

interface PropsI {}

const BillingCompanyInvoiceView: React.FC<PropsI> = (props) => {
  const [purchases, setPurchases] = useState<any[]>([])
  useEffect(() => {
    fetchCompanyPurchases()
      .then((res) => {
        setPurchases(res.data)
      })
      .catch((err) => console.log(err))
  }, [])
  const columns: Column[] = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Amount',
      accessor: 'enrolledCurriculum.price',
      Cell: (tableProps: any) => {
        return tableProps.value
          ? `$${tableProps.value.toLocaleString()}`
          : `$${(0).toLocaleString()}`
      },
    },
    {
      Header: 'Payment method',
      accessor: 'paymentMethod',
    },
    {
      Header: 'Coach',
      accessor: 'enrolledCurriculum.coach.displayName',
    },
  ]

  // console.log(purchases)
  return (
    <div>
      <BaseTable columns={columns} data={purchases} />
    </div>
  )
}

export default BillingCompanyInvoiceView
