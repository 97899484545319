import React from 'react'
import { connect } from 'react-redux'

import { ThunkDispatch } from 'redux-thunk'
import css from './NavBar.module.scss'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Avatar } from '@material-ui/core'
import VivekaLogo from '../../assets/viveka-logo.png'
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { logout } from '../../ducks/auth.duck'
import { changeCurrentCompany } from '../../ducks/user.duck'
import { vivekaUrl } from '../../env-config'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const NavBar: React.FC<PropsI> = (props) => {
  const { logoutA, history, currentUser, currentCompany, changeCurrentCompanyA } = props
  const currentCompanyId = currentUser?.currentCompanyId

  const avatarName = currentUser
    ? currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)
    : 'V'

  const menuItemStyles = {
    hover: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
    active: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
  }

  const roles: string[] = currentCompany?.UserCompanyModel.roles

  return (
    <div className={css.base}>
      <a className={css.logo} href={vivekaUrl}>
        <img alt="Logo" src={VivekaLogo} />
      </a>

      {/*<div className={css.company}>{currentUser?.currentCompany?.name}</div>*/}

      {/*<div className={css.links}>*/}
      {/*  {roles?.includes('hr') && (*/}
      {/*    <Link className={css.link} to="/hr">*/}
      {/*      HR Dashboard*/}
      {/*    </Link>*/}
      {/*  )}*/}

      {/*  {roles?.includes('employee') && (*/}
      {/*    <Link className={css.link} to="/employee">*/}
      {/*      Employee Dashboard*/}
      {/*    </Link>*/}
      {/*  )}*/}

      {/*  {roles?.includes('coach') && (*/}
      {/*    <Link className={css.link} to="/coach">*/}
      {/*      Coach Dashboard*/}
      {/*    </Link>*/}
      {/*  )}*/}
      {/*</div>*/}

      <div className={css.avatar_base}>
        <Menu
          direction="left"
          menuButton={
            <Avatar className={css.avatar} src={currentUser?.profilePic}>
              {avatarName}
            </Avatar>
          }
        >
          {currentUser?.companies && (
            <SubMenu label="Switch Company">
              {currentUser.companies.map((item: any, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    disabled={currentCompanyId === item.id}
                    styles={menuItemStyles}
                    onClick={() => {
                      changeCurrentCompanyA(item.id)
                      return true
                    }}
                  >
                    {item.name}
                  </MenuItem>
                )
              })}
              {/*<MenuItem*/}
              {/*  styles={{ color: css.primaryColor, ...menuItemStyles }}*/}
              {/*  onClick={() => {*/}
              {/*    history.push('/my-companies/create')*/}
              {/*    return true*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Create a Company*/}
              {/*</MenuItem>*/}
            </SubMenu>
          )}

          {/*<MenuItem*/}
          {/*  styles={menuItemStyles}*/}
          {/*  onClick={() => {*/}
          {/*    history.push('/employee/')*/}
          {/*    return true*/}
          {/*  }}*/}
          {/*>*/}
          {/*  My Personal Dashboard*/}
          {/*</MenuItem>*/}

          <MenuItem
            styles={menuItemStyles}
            onClick={() => {
              logoutA().then(() => history.push('/auth/login'))
              return true
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

interface MapStatePropsI {
  currentUser: any
  currentCompany: any
}

interface MapDispatchPropsI {
  logoutA: () => any
  changeCurrentCompanyA: (companyId: number) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
    currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    logoutA: () => dispatch(logout()),
    changeCurrentCompanyA: (companyId: number) => dispatch(changeCurrentCompany(companyId)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))
