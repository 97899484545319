import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchCompanyEmployees = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/get-company-employees`
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const addOrRemoveEmployeeFromProgram = async (
  curriculumId: string,
  userId: string,
  isAdd: boolean
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/add-remove-employee-from-program`,
        {
          curriculumId,
          userId,
          isAdd,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCurriculumEmployees = async (curriculumId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/get-curriculum-employees`,
        { curriculumId }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
