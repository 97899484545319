import axios from 'axios'
import jwt from 'jsonwebtoken'
import { refreshAccessToken } from './authentication'

// export const setAxiosInterceptors = () => {
//   axios.interceptors.request.use(
//     async (config) => {
//       config.withCredentials = true
//       config.headers['content-type'] = 'application/json'
//
//       const accessToken = localStorage.getItem('access_token')
//       const refresh_token = localStorage.getItem('refresh_token')
//
//       if (!accessToken || !refresh_token) {
//         return config
//       }
//
//       const jwtData = jwt.decode(accessToken || '', { complete: true })
//       let exp = jwtData?.payload.exp * 1000
//
//       if (exp > new Date().getTime()) {
//         config.headers.authorization = `bearer ${accessToken}`
//         return config
//       } else {
//         if (refresh_token) {
//           const refreshedAccessToken = await refreshAccessToken(refresh_token)
//           console.log('set new access token ', refreshedAccessToken)
//           config.headers.authorization = `bearer ${refreshedAccessToken}`
//           return config
//         } else {
//           return config
//         }
//       }
//     },
//     (err) => {}
//   )
//
//   // axios.interceptors.response.use(
//   //   (res: AxiosResponse<ApiResponseI>) => {
//   //     // console.log({ res })
//   //     // if (res.data?.data.access_token) {
//   //     //   Cookies.set('access_token', res.data.data.access_token)
//   //     // }
//   //     // if (res.data?.data.refresh_token) {
//   //     //   Cookies.set('refresh_token', res.data.data.refresh_token)
//   //     // }
//   //     return res
//   //   },
//   //   (err) => {}
//   // )
// }

export const authAxios = axios.create()
authAxios.interceptors.request.use(
  async (config) => {
    config.headers['content-type'] = 'application/json'

    const accessToken = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')

    if (!accessToken || !refresh_token) {
      return config
    }

    const jwtData: any = jwt.decode(accessToken, { complete: true })

    let exp = Number(jwtData?.payload.exp || 1) * 1000

    if (exp > new Date().getTime()) {
      config.headers.authorization = `bearer ${accessToken}`
      return config
    } else {
      if (refresh_token) {
        const refreshedAccessToken = await refreshAccessToken(refresh_token)
        config.headers.authorization = `bearer ${refreshedAccessToken}`
        return config
      } else {
        return config
      }
    }
  },
  (err) => err
)
