import React, { useCallback, useEffect, useState } from 'react'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import {
  fetchBudgetRecords,
  fetchEmployeeDetails,
  fetchEmployeeEnrolledCurriculums,
} from './HREmployeeViewPage.helper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './HREmployeeViewPage.module.scss'
import { Avatar, Checkbox, Tab, Tabs, Tooltip } from '@material-ui/core'
import EmployeeBudgetAllocatePopup from '../../../components/EmployeeBudgetAllocatePopup/EmployeeBudgetAllocatePopup'
import { CellProps, Column } from 'react-table'
import moment from 'moment'
import BaseTable from '../../../components/BaseTable/BaseTable'
import { AvatarGroup } from '@material-ui/lab'

interface PropsI extends RouteComponentProps<{ id: string }> {}

const HREmployeeViewPage: React.FC<PropsI> = (props) => {
  const { match } = props
  const [enrolledItems, setEnrolledItems] = useState<any[]>([])
  const [userDetails, setUserDetails] = useState<any>()
  const [budgetRecords, setBudgetRecords] = useState<any[]>([])
  const [tab, setTab] = useState<any>(0)

  // const [budgetDetails, setBudgetDetails] = useState()
  // const fetchBudgetRecord = () => {
  //   fetchBudgetRecords(match.params.id)
  //     .then((res) => {
  //       setBudgetRecords(res.data)
  //     })
  //     .catch((err) => console.log(err))
  // }

  const fetchBudgetRecordCB = useCallback(() => {
    fetchBudgetRecords(match.params.id)
      .then((res) => {
        setBudgetRecords(res.data)
      })
      .catch((err) => console.log(err))
  }, [match.params.id])

  const fetchEmployeeEnrolledCurriculumsCB = useCallback(() => {
    fetchEmployeeEnrolledCurriculums(match.params.id)
      .then((res) => {
        setEnrolledItems(res.data)
      })
      .catch((err) => console.log(err))
  }, [match.params.id])

  const fetchEmployeeDetailsCB = useCallback(() => {
    fetchEmployeeDetails(match.params.id)
      .then((res) => {
        setUserDetails(res.data)
      })
      .catch((err) => console.log(err))
  }, [match.params.id])

  const loadPageCB = useCallback(() => {
    fetchEmployeeEnrolledCurriculumsCB()
    fetchEmployeeDetailsCB()
    fetchBudgetRecordCB()
  }, [fetchBudgetRecordCB, fetchEmployeeDetailsCB, fetchEmployeeEnrolledCurriculumsCB])

  useEffect(() => {
    loadPageCB()
  }, [loadPageCB])

  // const currentBudget = userDetails?.UserCompanyModel?.currentDiscretionaryBudget
  //   ? userDetails?.UserCompanyModel?.currentBudget
  //   : 0
  //
  // const utilized = userDetails?.UserCompanyModel?.utilized
  //   ? userDetails?.UserCompanyModel?.utilized
  //   : 0

  const tabHandleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const enrolledColumns: Column[] = [
    {
      Header: 'Curriculum',
      accessor: 'curriculum.name',
    },
    {
      Header: 'Category',
      accessor: 'curriculum.categories',
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps.value.map((item: any) => (
              <Tooltip key={item.id} title={`${item.name}`}>
                <Avatar sizes="10px" alt={`${item.name.charAt(0)}`}>
                  {item.name.charAt(0)}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Fulfilment',
      accessor: 'listing.name',
    },
    // {
    //   Header: 'Price',
    //   accessor: 'price',
    //   Cell: (tableProps: any) => {
    //     return tableProps.value
    //       ? `$${tableProps.value.toLocaleString()}`
    //       : `$${(0).toLocaleString()}`
    //   },
    // },
    // {
    //   Header: 'Coach',
    //   accessor: 'coach.displayName',
    // },
    {
      Header: 'Required',
      accessor: '',
      Cell: (cellProps: CellProps<any>) => {
        const type = cellProps.row.original?.curriculum?.type || ''
        if (type === 'required') {
          return (
            <Checkbox value={true} checked disableFocusRipple disableRipple disableTouchRipple />
          )
        } else {
          return null
        }
      },
    },
    {
      Header: 'Discretionary',
      accessor: '',
      Cell: (cellProps: CellProps<any>) => {
        const type = cellProps.row.original?.curriculum?.type || ''
        if (type !== 'required') {
          return (
            <Checkbox value={true} checked disableFocusRipple disableRipple disableTouchRipple />
          )
        } else {
          return null
        }
      },
    },
    // {
    //   Header: 'Date',
    //   accessor: 'createdAt',
    //   Cell: (tableProps: any) => {
    //     const dateString = moment(tableProps.value).format('DD / M / YYYY')
    //     const fromNow = moment(tableProps.value).fromNow()
    //     return (
    //       <Tooltip title={fromNow}>
    //         <p>{dateString}</p>
    //       </Tooltip>
    //     )
    //   },
    // },
  ]

  const budgetColumns: Column[] = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },

    // {
    //   Header: 'Description',
    //   accessor: 'description',
    //   Cell: (tableProps: any) => {
    //     return (
    //       <AvatarGroup max={5}>
    //         {tableProps.value.map((item: any) => (
    //           <Tooltip key={item.id} title={`${item.name}`}>
    //             <Avatar sizes="10px" alt={`${item.name.charAt(0)}`}>
    //               {item.name.charAt(0)}
    //             </Avatar>
    //           </Tooltip>
    //         ))}
    //       </AvatarGroup>
    //     )
    //   },
    // },
    {
      Header: 'Description',
      accessor: 'description',
    },

    {
      Header: 'Remark',
      accessor: 'remark',
      Cell: (tableProps: any) => {
        if (tableProps.value) {
          return tableProps.value
        } else {
          return 'N/A'
        }
      },
    },
    // {
    //   Header: 'Amount',
    //   accessor: 'amount',
    //   Cell: (tableProps: CellProps<any>) => {
    //     let str = ''
    //     if (tableProps.value && tableProps.value !== 0) {
    //       str = '$' + tableProps?.value?.toLocaleString()
    //     } else {
    //       str = '$0'
    //     }
    //     return (
    //       <span style={{ color: tableProps.row.original.isAdding ? 'green' : 'red' }}>{str}</span>
    //     )
    //   },
    // },

    {
      Header: 'Allocated',
      accessor: '',
      Cell: (cellProps: CellProps<any>) => {
        const amount = cellProps.row.original.amount
        const isAdding = cellProps.row.original.isAdding
        if (!isAdding) return ''
        // return amount
        let str = ''
        if (amount && amount !== 0) {
          str = '$' + amount?.toLocaleString()
        } else {
          str = '$0'
        }
        return str
      },
    },

    {
      Header: 'Utilized',
      accessor: '',
      Cell: (cellProps: CellProps<any>) => {
        const amount = cellProps.row.original.amount
        const isAdding = cellProps.row.original.isAdding
        if (isAdding) return ''
        // return amount
        let str = ''
        if (amount && amount !== 0) {
          str = '$' + amount?.toLocaleString()
        } else {
          str = '$0'
        }
        return str
      },
    },

    {
      Header: 'Type (R/D)',
      accessor: 'budgetType',
      Cell: (cellProps) => {
        return cellProps.value === 'required' ? 'R' : 'D'
      },
    },

    // {
    //   Header: 'Increase (+) / Decrease (-)',
    //   accessor: 'isAdding',
    //   Cell: (tableProps: any) => {
    //     return <div>{tableProps.value ? <Plus /> : <Minus />}</div>
    //     // if (tableProps.value) {
    //     //   return '+'
    //     // } else {
    //     //   return '-'
    //     // }
    //   },
    // },
  ]

  const dBudget = userDetails?.UserCompanyModel?.currentDiscretionaryBudget || 0
  const rBudget = userDetails?.UserCompanyModel?.currentRequiredBudget || 0

  const dUtilized = userDetails?.UserCompanyModel?.discretionaryUtilized || 0
  const rUtilized = userDetails?.UserCompanyModel?.requiredUtilized || 0

  return (
    <MainDashboardWrapper title="Employee" page="corp-employees">
      <p className={css.title}>{userDetails?.displayName}'s Programs</p>

      <div className={css.details}>
        <div>
          <div className={css.avatar}>
            <Avatar style={{ height: '70px', width: '70px' }} src={userDetails?.profilePic} />
            <div className={css.avatar_details}>
              <p>{userDetails?.displayName}</p>
              <p>{userDetails?.email}</p>
            </div>
          </div>
        </div>

        <div className={css.budget_card}>
          <p>Remaining Budget (R/D)</p>
          <p>
            ${rBudget.toLocaleString()} / ${dBudget.toLocaleString()}
          </p>
        </div>

        <div className={css.budget_card}>
          <p>Utilized (R/D)</p>
          <p>
            ${rUtilized.toLocaleString()} / ${dUtilized.toLocaleString()}
          </p>
        </div>

        <div>
          <EmployeeBudgetAllocatePopup
            loadPageCB={loadPageCB}
            employeeId={match.params.id}
            trigger={<div className={css.button}>Allocate Funds</div>}
          />
        </div>
      </div>

      {/*<div className={css.details}>*/}
      {/*  <div className={css.budget_card}>*/}
      {/*    <p>Budget</p>*/}
      {/*    <p>${(dBudget + rBudget).toLocaleString()}</p>*/}
      {/*  </div>*/}

      {/*  <div className={css.budget_card}>*/}
      {/*    <p>Utilized</p>*/}
      {/*    <p>${(dUtilized + rUtilized).toLocaleString()}</p>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Tabs className={css.tabNav} value={tab} onChange={tabHandleChange}>
        <Tab label="Learning Goals" />
        <Tab label="Budget" />
      </Tabs>

      {tab === 0 && (
        <div>
          <BaseTable columns={enrolledColumns} data={enrolledItems} />
        </div>
      )}
      {tab === 1 && (
        <div>
          <BaseTable columns={budgetColumns} data={budgetRecords} />
        </div>
      )}
    </MainDashboardWrapper>
  )
}

export default withRouter(HREmployeeViewPage)
